<template>
  <b-container fluid>
    <b-row>
      <b-col lg="4">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('PersonalInformations') }}</h4>
          </template>
          <template v-slot:body>
            <Loader v-if="loading" :is-visible="loading"/>
            <div v-else class="container m-0 p-0 d-flex flex-column justify-content-between align-items-start h-100">
              <div class="row text-dark">
                <div v-if="customerInfo.customerType" class="col-5">{{ $t('CustomerType') }}:</div>
                <div v-if="customerInfo.customerType" class="col-7">
                  <div>
                    <span v-bind:class="customerInfo.customerTypeState=='+' ? 'badge font-weight-bold iq-bg-success':customerInfo.customerTypeState=='o' ? 'badge iq-bg-warning':customerInfo.customerTypeState=='-' ? 'badge iq-bg-danger':'badge iq-bg-info'">{{customerInfo.customerType}}</span>
                  </div>
                </div>
                <div v-if="customerInfo.nationalNumber" class="col-5">{{ $t('NationalNumber') }}:</div>
                <div v-if="customerInfo.nationalNumber" class="col-7">
                  <span>{{ customerInfo.nationalNumber }} </span>
                </div>
                <div v-if="customerInfo.gender" class="col-5">{{ $t('Gender') }}:</div>
                <div v-if="customerInfo.gender" class="col-7">
                  <div>
                    <span>{{ $t(customerInfo.gender) }} </span>
                  </div>
                </div>
                <div v-if="customerInfo.dateOfBirth" class="col-5">{{ $t('DateOfBirth') }}:</div>
                <div v-if="customerInfo.dateOfBirth" class="col-7">
                  <div>
                    <span>{{ customerInfo.dateOfBirth | dateFormat }} - {{ customerInfo.age }}</span>
                  </div>
                </div>
                <div v-if="customerInfo.email" class="col-5">{{ $t('Email') }}:</div>
                <div v-if="customerInfo.email" class="col-7">
                  <div>
                    <span>{{ customerInfo.email }}</span>
                  </div>
                </div>
                <div v-if="customerInfo.createdStr" class="col-5">{{ $t('Created') }}:</div>
                <div v-if="customerInfo.createdStr" class="col-7">
                  <div>
                    <span>{{ customerInfo.createdStr }}</span>
                  </div>
                </div>
                <div v-if="customerInfo.city || customerInfo.town" class="col-5">{{ $t('Town') }}-{{ $t('City') }}:</div>
                <div v-if="customerInfo.city || customerInfo.town" class="col-7">
                  <div>
                    <span v-if="customerInfo.town">{{ customerInfo.town }}</span>
                    <span v-if="customerInfo.city && customerInfo.town">-</span>
                    <span v-if="customerInfo.city">{{ customerInfo.city }}</span>
                  </div>
                </div>
                <div v-if="customerInfo.country" class="col-5">{{ $t('Country') }}:</div>
                <div v-if="customerInfo.country" class="col-7">
                  <div>
                    <span>{{ customerInfo.country }}</span>
                  </div>
                </div>
                <div v-if="customerInfo.language" class="col-5">{{ $t('Language') }}:</div>
                <div v-if="customerInfo.language" class="col-7">
                  <div>
                    <span>{{ $t(customerInfo.language) }}</span>
                  </div>
                </div>
                <div v-if="customerInfo.job" class="col-5">{{ $t('Job') }}:</div>
                <div v-if="customerInfo.job" class="col-7">
                  <div>
                    <span>{{ $t(customerInfo.job) }}</span>
                  </div>
                </div>
                <div v-if="customerInfo.job" class="col-5">{{ $t('RegisterBy') }}:</div>
                <div v-if="customerInfo.job" class="col-7">
                  <div>
                    <span>{{ $t(customerInfo.createdBy) }}</span>
                  </div>
                </div>
                <div v-if="customerInfo.job" class="col-5">{{ $t('ReferenceSource') }}:</div>
                <div v-if="customerInfo.job" class="col-7">
                  <div>
                    <span class="badge badge-info">{{ customerInfo.referenceSource }}</span>
                  </div>
                </div>
                <div v-if="customerInfo.job" class="col-5">{{ $t('ModifiedBy') }}:</div>
                <div v-if="customerInfo.job" class="col-7">
                  <div>
                    <span>{{ $t(customerInfo.modifiedBy) }}</span>
                  </div>
                </div>
                <div v-if="customerInfo.job" class="col-5">{{ $t('Modified') }}:</div>
                <div v-if="customerInfo.job" class="col-7">
                  <div>
                    <span>{{ $t(customerInfo.modifiedStr) }}</span>
                  </div>
                </div>
              </div>

              <ul class="doctoe-sedual d-flex align-items-center justify-content-between p-0 mt-4 mb-0 w-100">
                <li class="text-center">
                  <h6 class="text-primary">{{ $t('Appointment') }}</h6>
                  <h3>{{ appointmentCount }}</h3>
                </li>
                <li class="text-center">
                  <h6 class="text-primary">{{ $t('Bill') }}</h6>
                  <h3>{{ billCount }}</h3>
                </li>
              </ul>
            </div>
          </template>
          <template v-slot:footer>
            <button class="btn btn-sm btn-block btn-outline-danger mt-2"
                    @click="showCustomerDeleteConfirmation"
                    style="margin-bottom: 2%; padding: 1% 0 1%;">
              <i class="la la-trash-o" style="margin-top:-1%;" />
              <span>{{ $t('Delete') }}</span>
            </button>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="4">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('TreatmentHistory')}}</h4>
          </template>
          <template v-slot:body>
            <Loader v-if="loading" :is-visible="loading" />
            <div v-if="!loading && customerHistory && customerHistory.length > 0">
              <ul class="iq-timeline treatment-lists">
                <li v-for="(item, index) in customerHistory" :key="index">
                  <div class="timeline-dots" :class="item.type == 'Bill' ? 'border-success' : item.type == 'Appointment' ? 'border-warning' : 'border-brand'"></div>

                  <small class="float-right">{{ item.date | dateFormat }}</small>

                  <h6 class="mb-1">
                    <strong>{{ item.type == 'Bill' ?  $t('Payment') : item.type == 'Appointment' ? $t('Appointment') : '' }}</strong>
                  </h6>

                  <div class="my-2 text-dark">
                    <span>{{ item.serviceID != null && item.serviceID.length > 0 ?  $t('Service') : item.productID != null && item.productID.length > 0 ? $t('Product') : item.packageID != null && item.packageID.length > 0 ? $t('Package') :'' }}:</span>
                    <span class="text-primary font-weight-bold">
                      {{item.serviceID != null && item.serviceID.length > 0 ? item.serviceName : item.productID != null && item.productID.length > 0 ? item.productName : item.packageID != null && item.packageID.length > 0 ? item.packageName :''}}
                    </span>
                    <br>
                    <span>{{ $t('Staff') }}: </span>
                    {{ item.staffName }}
                    <br>
                    <span>{{ $t('Date') }}: </span>
                    {{ item.date | dateFormatWithHourForContainingT }}
                    <span v-if="item.type == 'Appointment'" v-bind:class="item.baseStatus=='+'? 'badge iq-bg-success' : item.baseStatus=='o' ? 'badge iq-bg-warning' :  item.baseStatus=='o' ? 'badge iq-bg-danger' : 'badge iq-bg-info'">
                      {{item.status}}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div v-else>
              <div class="h-100 w-100">
                <div class="d-flex justify-content-center align-items-center">
                  {{$t('NoTreatmentRecord')}}
                </div>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="4">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('Notifications') }}</h4>
          </template>
          <template v-slot:body>
            <Loader v-if="loading" :is-visible="loading" />
            <div v-if="!loading && customerNotification && customerNotification.length > 0">
              <ul class="speciality-list notification-list m-0 p-0">
                <li v-for="(feed,index) in customerNotification" :key="index" class="d-flex mb-4 align-items-center">
                  <div class="user-img img-fluid">
                    <a class="iq-bg-primary">
                      <i :class="feed.icon" />
                    </a>
                  </div>
                  <div class="media-support-info ml-3">
                    <h6>{{ $t(feed.feedText) }}</h6>
                    <span class="badge badge-warning">
                      {{ feed.date | dateFormatWithHourForContainingT }}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div v-else>
              <div class="h-100 w-100">
                <div class="d-flex justify-content-center align-items-center">
                  {{$t('NoNotificationRecord')}}
                </div>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <iq-card>
          <div class="iq-card-header d-flex justify-content-between" @click="collapseTagsToggle" style="cursor: pointer;">
            <div class="iq-header-title">
              <h4 class="card-title">{{ $t('Tags') }}</h4>
            </div>
            <div class="iq-card-header-toolbar d-flex align-items-center">
              <font-awesome-icon :icon="showBodyForTags ? 'fas fa-chevron-up' : 'fas fa-chevron-down'" />
            </div>
          </div>
          <div v-show="showBodyForTags" class="card-body iq-card-body">
            <div v-if="customerTags && customerTags.length > 0" class="tab-content">
              <span v-for="(tag, index) in customerTags" :key="index" class="badge mr-2 p-2 badge-primary badge-pill">{{tag.tag.name}}</span>
            </div>
            <div v-else>
              <div class="section" style="height:100%; width:100%;">
                <div class="d-flex justify-content-center align-items-center">
                  {{$t('NoDataRecord')}}
                </div>
              </div>
            </div>
          </div>
        </iq-card>
      </b-col>
      <b-col md="12">
        <iq-card style="color:black;">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('MedicalInformations') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-form-group class="col-md-4" :label="$t('Allergies')" disabled>
                <b-form-textarea v-model="customerInfo.allergies" rows="2"></b-form-textarea>
              </b-form-group>
              <b-form-group class="col-md-4" :label="$t('Drugs')" disabled>
                <b-form-textarea v-model="customerInfo.drugs" rows="2"></b-form-textarea>
              </b-form-group>
              <b-form-group class="col-md-4" :label="$t('HealthIssues')" disabled>
                <b-form-textarea v-model="customerInfo.healthIssues" rows="2"></b-form-textarea>
              </b-form-group>
              <b-form-group class="col-md-4" :label="$t('Operations')" disabled>
                <b-form-textarea v-model="customerInfo.operations" rows="2"></b-form-textarea>
              </b-form-group>
              <b-form-group class="col-md-4" :label="$t('AlcoholOrSmoke')" disabled>
                <b-form-textarea v-model="customerInfo.alcoholOrSmoke" rows="2"></b-form-textarea>
              </b-form-group>
              <b-form-group class="col-md-4" :label="$t('ChronicIllness')" disabled>
                <b-form-textarea v-model="customerInfo.chronicIllness" rows="2"></b-form-textarea>
              </b-form-group>
              <b-form-group class="col-md-4" :label="$t('PreviousApplications')" disabled>
                <b-form-textarea v-model="customerInfo.previousApplications" rows="2"></b-form-textarea>
              </b-form-group>
              <b-form-group class="col-md-4" :label="$t('TreatmentsWithMedicalDevices')" disabled>
                <b-form-textarea v-model="customerInfo.treatmentsWithMedicalDevices" rows="2"></b-form-textarea>
              </b-form-group>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import CustomerService from '../../../services/customer';
  import { deleteConfirmation } from '../../../helpers/swalConfirmations';

  import Loader from '../../../components/shared/Loader';

  export default {
    name: 'SummaryDetails',
    props: {
      customerId: String
    },
    components: {
      Loader
    },
    data() {
      return {
        customerInfo: {},
        customerHistory: [],
        customerSettings: [],
        customerNotification: [],
        customerTags: [],

        selectedFile: null,
        profilePicture: {},

        showBodyForTags: false,
        billCount: 0,
        appointmentCount: 0,

        loading: true,
      }
    },
    methods: {
      getCustomerInfo() {
        CustomerService.getCustomerInfo(this.customerId)
          .then(response => {
            if (response && response.id == this.customerId) {
              this.customerInfo = response;
              this.notAuthorized = false;

              if (this.customerInfo.segmentColor == null)
                this.customerInfo.segmentColor = "success";
            }
          }).finally(() => {
            this.loading = false;
          });
      },
      getCustomerHistory() {
        CustomerService.getCustomerHistory(this.customerId)
          .then(response => {
            this.customerHistory = response;
            const bills = this.customerHistory.filter(obj => obj.type === "Bill");
            const appointments = this.customerHistory.filter(obj => obj.type === "Appointment");

            this.billCount = bills.length;
            this.appointmentCount = appointments.length;
          }).finally(() => {
            this.loading = false;
          });
      },
      getCustomerNotification() {
        CustomerService.getCustomerNotification(this.customerId)
          .then(response => {
            this.customerNotification = response;
          });
      },
      getCustomerTags() {
        CustomerService.getCustomerTags(this.customerId)
          .then(response => {
            this.customerTags = response;
          });
      },
      getCustomerSettings() {
        CustomerService.getCustomerSetting()
          .then(response => {
            this.customerSettings = response;
          });
      },
      getCustomerProfilePicture() {
        CustomerService.getCustomerProfilePicture(this.customerId)
          .then(response => {
            this.profilePicture = response;
          }).finally(() => {
            this.loading = false;
          });
      },

      collapseTagsToggle() {
        if (!this.showBodyForTags)
          this.showBodyForTags = true;
        else
          this.showBodyForTags = false;
      },
      showCustomerDeleteConfirmation() {
        if (this.customerId) {
          deleteConfirmation(this.customerId, "/Customer/Delete");
        }
      },
    },
    mounted() {
      window.scrollTo(0, 0);

      this.getCustomerInfo();
      this.getCustomerSettings();
      this.getCustomerProfilePicture();
      this.getCustomerHistory();
      this.getCustomerNotification();
      this.getCustomerTags();
    }
  }
</script>
<style scoped>
  .treatment-lists {
    height: 500px;
    overflow-y: scroll;
  }
  .notification-list {
    height: 500px;
    overflow-y: scroll;
  }
  .about-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
  }

  .doctoe-sedual {
    align-self: flex-end;
  }
</style>
